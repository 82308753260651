export const environment = {
  production: true,
  apiBaseUrl: 'https://b101t03as-backend01.azurewebsites.net',
  clientId: 'be9981a9-aa6a-4713-89c4-34dc18bee57a',
  authority: 'https://login.microsoftonline.com/fad277c9-c60a-4da1-b5f3-b3b8b34a82f9',
  redirectUri: 'https://pp.funding-data.education.gov.uk/.auth/login/aad/callback',
  scope: ['https://educationgovuk.onmicrosoft.com/fundingdataserviceapipp/user_impersonation'],
  sysAdminGroup: 'AZURE-FDS-PP-SYSADMIN-USERS',
  useLocalHttpInterceptor: false,
  localToken: '',
  environment: 'preprod',
  clarityProjectId: ''
};
